const { onPlayerSetup, setupPlayers, performPlayerPlay } = require('../../common/js/perform');

class BcVideoPlayer {
  constructor () {
    this._listeners = [];
    this._player = null;
  }

  init () {
    // listen for videojs to be ready
    onPlayerSetup(player => {
      this._player = player;

      player.ready(() => {
        this.dispatchEvent(BcVideoPlayer.EVENT_TEMPLATE_LOADED);
      });

      player.on('loadedmetadata', () => {
        this.dispatchEvent(BcVideoPlayer.EVENT_METADATA_LOADED);

        // @TODO - check for validity of this statement
        // Safari doesn't seem to trigger 'play' on first load
        // so we "hack" that fact by triggering that event here
        this.dispatchEvent(BcVideoPlayer.EVENT_VIDEO_STARTED);
      });

      player.on('play', () => {
        this.dispatchEvent(BcVideoPlayer.EVENT_VIDEO_STARTED);
      });

      player.on('ended', () => {
        this.dispatchEvent(BcVideoPlayer.EVENT_VIDEO_ENDED);
      });
    });

    setupPlayers();
  }

  addEventListener (eventName, callback) {
    if (!(eventName in this._listeners)) {
      this._listeners[eventName] = [];
    }
    this._listeners[eventName].push(callback);
  }

  removeEventListener (eventName, callback) {
    if (eventName in this._listeners) {
      this._listeners[eventName] = this._listeners[eventName].filter(function (cb) {
        return cb !== callback;
      });
    }
  }

  dispatchEvent (eventName, eventData) {
    if (eventName in this._listeners) {
      for (let i = 0, max = this._listeners[eventName].length; i < max; i++) {
        try {
          this._listeners[eventName][i](this, eventData);
        } catch (e) {
          console.warn('BcVideoPlayer - [' + eventName + '] callback error');
          console.warn(e);
          return;
        }
      }
    }
  }

  isTemplateLoaded () {
    return this._player !== null;
  }

  pause () {
    if (!this.isTemplateLoaded()) {
      console.warn('BcVideoPlayer - player not ready');
      return;
    }
    this._player.pause();
  }

  play () {
    if (!this.isTemplateLoaded()) {
      console.warn('BcVideoPlayer - player not ready');
      return;
    }
    this._player.play();
  }

  playVideo (video) {
    if (!this.isTemplateLoaded()) {
      console.warn('BcVideoPlayer - player not ready');
      return;
    }
    performPlayerPlay(this._player, video, (err) => {
      if (err) {
        console.warn('BcVideoPlayer - error loading video');
        console.warn(err);
        this.dispatchEvent(BcVideoPlayer.EVENT_VIDEO_ERROR, {
          video,
        });
      }
    });

  }
}

BcVideoPlayer.EVENT_TEMPLATE_LOADED = 'EVENT_TEMPLATE_LOADED';
BcVideoPlayer.EVENT_METADATA_LOADED = 'EVENT_METADATA_LOADED';
BcVideoPlayer.EVENT_VIDEO_STARTED = 'EVENT_VIDEO_STARTED';
BcVideoPlayer.EVENT_VIDEO_ENDED = 'EVENT_VIDEO_ENDED';
BcVideoPlayer.EVENT_VIDEO_ERROR = 'EVENT_VIDEO_ERROR';

module.exports = BcVideoPlayer;
